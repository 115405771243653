#header_wrapper{min-height: 520px;}
#header_inner{min-height: 250px;}

#row {
	display: flex; /* equal height of the children */
}
.col1 {
	padding-top: 3%;
	padding-left: 3%;
	flex-basis: 75%;
  }
.col2 {
	padding-top: 3%;
	padding-left: 3%;
	flex-basis: 25%;
	text-align: right;
  }

.script {
	font-size: 25px;
	padding: 5%;
	word-spacing: 1px;
  }

.rcorners2 {
    margin-left: auto;
	margin-right: auto;
	margin-bottom: 15px;
	width: min(600px,80%);
	line-height: 37px;
	font-family: 'bebas_neueregular';
	overflow: visible; 
	word-spacing: 5px;
	color: black;
	padding: 5px 10px 5px 0px;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	font-size: 40px;
	border: 1px solid rgba(99, 98, 98, 0.199);
	-moz-border-radius: 35px;
	-webkit-border-radius: 35px;
	border-radius: 15px;
	-moz-background-clip: border-box;
	-webkit-background-clip: border-box;
	background-clip: border-box;
	background-color: #fff;
	-moz-box-shadow: 1px 1px 3px rgba(0,0,0,.11);
	-webkit-box-shadow: 1px 1px 3px rgba(0,0,0,.11);
	box-shadow: 1px 1px 3px rgba(0,0,0,.11);
}
#header_wrapper{
	width: 100%;
	background-color: #e9e77e;
	background-image: url('./assets/banner.png');
	background-size: 900px;
	background-position: center bottom;
	background-repeat: no-repeat;
	position: relative;
	-moz-box-shadow: inset -3px -4px 5px rgba(189,189,189,.17);
	-webkit-box-shadow: inset -3px -4px 5px rgba(189, 189, 189, 0.17);
	box-shadow: inset -3px -4px 5px rgba(189,189,189,.17);
}
#header_inner{
	width: 100%;
	background-image: url('./assets/title.png');
	background-position: center top;
	background-size: min(400px,100%);
	background-repeat: no-repeat;
	position: absolute;
	bottom: 0;
}
#search-terms-bar{
    margin-left: auto;
    margin-right: auto;
	width: min(600px,80%);
	line-height: 37px;
	font-family: 'bebas_neueregular';
	overflow: visible;
	word-spacing: 5px;
	color: #636363;
	padding: 10px 20px 8px 20px;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	font-size: 40px;
	border: 1px solid rgba(203,203,203,.2);
	-moz-border-radius: 35px;
	-webkit-border-radius: 35px;
	border-radius: 35px;
	-moz-background-clip: border-box;
	-webkit-background-clip: border-box;
	background-clip: border-box;
	background-color: #fff;
	-moz-box-shadow: 1px 1px 3px rgba(0,0,0,.11);
	-webkit-box-shadow: 1px 1px 3px rgba(0,0,0,.11);
	box-shadow: 1px 1px 3px rgba(0,0,0,.11);
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: rgba(77, 74, 74, 0.144);
	opacity: 1; /* Firefox */
  }
  

textarea:focus, input:focus{
    outline: none;
}

@font-face {
	font-family: "bebas_neueregular";
	src: local("bebas_neueregular"),
	  url("./fonts/BebasNeue-webfont.woff") format("woff");
	font-weight: normal;
  }
  @font-face {
    font-family: "Futura-Light";
    src: url("./fonts/FuturaLightRegular.otf") format("opentype");
}

.search-wrapper{
    text-align: center;
    margin-top: 150px;
}

html{
	background-color: #cacaca;
}
body{
	background-color: #cacaca !important;
}

#body_wrapper{
	background-color: #cacaca;
}

.footer{
	background-color: #cacaca;
	padding: 15px;
	text-align: center;
	font-size: 16px;
	font-family: 'Futura-Light';
	font-weight: normal;
	color: rgba(77, 74, 74, 0.61);
}